import CosmeticAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/cosmetic-allergies/CosmeticAllergiesTemplate';

export default {
  title: 'Steps/MedicalBackground/CosmeticAllergies/CosmeticAllergiesTemplate',
  component: CosmeticAllergiesTemplate
};

const createStory = props => () => ({
  components: { CosmeticAllergiesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><cosmetic-allergies-template v-bind="$options.storyProps"/></div>'
});

export const answeredQuestion = createStory({
  cosmeticAllergies: 'papain'
});

export const notAnsweredQuestion = createStory({
  cosmeticAllergies: ''
});
