<template>
  <div class="q-w360 q-fit">
    <broad-answer-question
      :value="cosmeticAllergies"
      title="label.cosmeticAllergies"
      @input="onFieldChange('cosmeticAllergies', $event)"
    />
  </div>
</template>

<script>
import BroadAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/BroadAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

export default {
  name: 'CosmeticAllergiesTemplate',
  components: { BroadAnswerQuestion },
  mixins: [stepTemplateMixin],
  props: {
    cosmeticAllergies: {
      type: String,
      default: ''
    }
  }
};
</script>
